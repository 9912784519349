import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";

import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";

import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";

import TagsTable from "./TagsTable";

import "./DwvComponent.css";
import { App, getDwvVersion, decoderScripts } from "dwv";
import { getToolIcon, blobsToFileList } from "./helper";

// Image decoders (for web workers)
decoderScripts.jpeg2000 = `${process.env.PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpeg2000.js`;
decoderScripts[
  "jpeg-lossless"
] = `${process.env.PUBLIC_URL}/assets/dwv/decoders/rii-mango/decode-jpegloss.js`;
decoderScripts[
  "jpeg-baseline"
] = `${process.env.PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js`;
decoderScripts.rle = `${process.env.PUBLIC_URL}/assets/dwv/decoders/dwv/decode-rle.js`;

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    flex: "0 0 auto",
  },
  iconSmall: {
    fontSize: 20,
  },
});

export const TransitionUp = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

class DwvComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      versions: {
        dwv: getDwvVersion(),
        react: React.version,
      },
      tools: {
        Scroll: {},
        ZoomAndPan: {},
        WindowLevel: {},
        Draw: {
          options: ["Ruler"],
        },
      },
      selectedTool: "Select Tool",
      loadProgress: 0,
      dataLoaded: false,
      dwvApp: null,
      metaData: {},
      orientation: undefined,
      showDicomTags: false,
    };
  }

  componentDidMount() {
    this.initializeApp();
  }

  componentDidUpdate(prevProps) {
    // Check if the file prop has changed
    if (prevProps.file.id !== this.props.file.id) {
      this.setState({ dwvApp: null, loadProgress: 0, dataLoaded: false });
      this.initializeApp();
    }
  }

  initializeApp() {
    const { file } = this.props;

    document.getElementById('layerGroup0').innerHTML = '';


    // create app
    const app = new App();

    // initialise app
    app.init({
      dataViewConfigs: { "*": [{ divId: "layerGroup0" }] },
      tools: this.state.tools,
    });

    // Add event listeners
    let nLoadItem = null;
    let nReceivedLoadError = null;
    let nReceivedLoadAbort = null;
    let isFirstRender = null;

    app.addEventListener("loadstart", () => {
      nLoadItem = 0;
      nReceivedLoadError = 0;
      nReceivedLoadAbort = 0;
      isFirstRender = true;
    });

    app.addEventListener("loadprogress", (event) => {
      this.setState({ loadProgress: event.loaded });
    });

    app.addEventListener("renderend", () => {
      if (isFirstRender) {
        isFirstRender = false;
        let selectedTool = "ZoomAndPan";
        if (app.canScroll()) {
          selectedTool = "Scroll";
        }
        this.onChangeTool(selectedTool);
      }
    });

    app.addEventListener("load", (event) => {
      this.setState({ metaData: app.getMetaData(event.dataid) });
      this.setState({ dataLoaded: true });
    });

    app.addEventListener("loadend", () => {
      if (nReceivedLoadError) {
        this.setState({ loadProgress: 0 });
        alert("Received errors during load. Check log for details.");
        if (!nLoadItem) {
          window.location.href = "https://onehealthassist.com/";
        }
      }
      if (nReceivedLoadAbort) {
        this.setState({ loadProgress: 0 });
        alert("Load was aborted.");
        window.location.href = "https://onehealthassist.com/";
      }
    });

    app.addEventListener("loaditem", () => {
      ++nLoadItem;
    });

    app.addEventListener("loaderror", (event) => {
      console.error(event.error);
      ++nReceivedLoadError;
    });

    app.addEventListener("loadabort", () => {
      ++nReceivedLoadAbort;
    });

    // Handle key events
    app.addEventListener("keydown", (event) => {
      app.defaultOnKeydown(event);
    });

    // Handle window resize
    window.addEventListener("resize", app.onResize);

    // Convert file blob to FileList and load files
    const files = blobsToFileList([file.blob]);
    app.loadFiles(files);

    // Store the app instance in state
    this.setState({ dwvApp: app });
  }

  render() {
    const { classes } = this.props;
    const { tools, loadProgress, dataLoaded, metaData } = this.state;

    const handleToolChange = (event, newTool) => {
      if (newTool) {
        this.onChangeTool(newTool);
      }
    };

    const toolsButtons = Object.keys(tools).map((tool) => {
      return (
        <ToggleButton
          value={tool}
          key={tool}
          title={tool}
          disabled={!dataLoaded || !this.canRunTool(tool)}
        >
          {getToolIcon(tool)}
        </ToggleButton>
      );
    });

    return (
      <div id="dwv">
        <LinearProgress variant="determinate" value={loadProgress} />
        <Stack
          direction="row"
          spacing={1}
          padding={1}
          justifyContent="center"
          flexWrap="wrap"
        >
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={this.state.selectedTool}
            exclusive
            onChange={handleToolChange}
          >
            {toolsButtons}
          </ToggleButtonGroup>

          <ToggleButton
            size="small"
            value="reset"
            title="Reset"
            disabled={!dataLoaded}
            onChange={this.onReset}
          >
            <RefreshIcon />
          </ToggleButton>

          <ToggleButton
            size="small"
            value="toggleOrientation"
            title="Toggle Orientation"
            disabled={!dataLoaded}
            onClick={this.toggleOrientation}
          >
            <CameraswitchIcon />
          </ToggleButton>

          <ToggleButton
            size="small"
            value="tags"
            title="Tags"
            disabled={!dataLoaded}
            onClick={this.handleTagsDialogOpen}
          >
            <LibraryBooksIcon />
          </ToggleButton>

          <Dialog
            open={this.state.showDicomTags}
            onClose={this.handleTagsDialogClose}
            TransitionComponent={TransitionUp}
          >
            <AppBar className={classes.appBar} position="sticky">
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={this.handleTagsDialogClose}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  DICOM Tags
                </Typography>
              </Toolbar>
            </AppBar>
            <TagsTable data={metaData} />
          </Dialog>
        </Stack>

        <div id="layerGroup0" className="layerGroup"></div>
      </div>
    );
  }

  /**
   * Handle a change tool event.
   * @param {string} tool The new tool name.
   */
  onChangeTool = (tool) => {
    if (this.state.dwvApp) {
      this.setState({ selectedTool: tool });
      this.state.dwvApp.setTool(tool);
      if (tool === "Draw") {
        this.onChangeShape(this.state.tools.Draw.options[0]);
      }
    }
  };


  /**
   * Check if a tool can be run.
   *
   * @param {string} tool The tool name.
   * @returns {boolean} True if the tool can be run.
   */
  canRunTool = (tool) => {
    if (!this.state.dwvApp) {
      return false; // Return false if dwvApp is not initialized
    }

    let res;
    if (tool === "Scroll") {
      res = this.state?.dwvApp?.canScroll();
    } else if (tool === "WindowLevel") {
      res = this.state?.dwvApp?.canWindowLevel();
    } else {
      res = true;
    }
    return res;
  };

  /**
   * Toogle the viewer orientation.
   */
  toggleOrientation = () => {
    if (typeof this.state.orientation !== "undefined") {
      if (this.state.orientation === "axial") {
        this.state.orientation = "coronal";
      } else if (this.state.orientation === "coronal") {
        this.state.orientation = "sagittal";
      } else if (this.state.orientation === "sagittal") {
        this.state.orientation = "axial";
      }
    } else {
      // default is most probably axial
      this.state.orientation = "coronal";
    }
    // update data view config
    const config = {
      "*": [
        {
          divId: "layerGroup0",
          orientation: this.state.orientation,
        },
      ],
    };
    this.state.dwvApp.setDataViewConfigs(config);
    // render data
    const dataIds = this.state.dwvApp.getDataIds();
    for (const dataId of dataIds) {
      this.state.dwvApp.render(dataId);
    }
  };

  /**
   * Handle a change draw shape event.
   * @param {string} shape The new shape name.
   */
  onChangeShape = (shape) => {
    if (this.state.dwvApp) {
      this.state.dwvApp.setToolFeatures({ shapeName: shape });
    }
  };

  /**
   * Handle a reset event.
   */
  onReset = () => {
    if (this.state.dwvApp) {
      this.state.dwvApp.resetDisplay();
    }
  };

  /**
   * Open the DICOM tags dialog.
   */
  handleTagsDialogOpen = () => {
    this.setState({ showDicomTags: true });
  };

  /**
   * Close the DICOM tags dialog.
   */
  handleTagsDialogClose = () => {
    this.setState({ showDicomTags: false });
  };
} // DwvComponent

DwvComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
};

export default withStyles(styles)(DwvComponent);
