import React, { useEffect, useState, lazy } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { orange, pink } from "@mui/material/colors";
import { Folders } from "./components";
import DwvComponent from "./DwvComponent";
import JSZip from "jszip";
import { extractFilesFromZip } from "./helper";
import { CircularProgress, Box } from "@mui/material";
import "./App.css";

export default function App() {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const isMobile = useMediaQuery("(max-width:600px)");

  const theme = createTheme({
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: orange[500],
      },
      secondary: {
        main: pink[500],
      },
      mode: prefersDarkMode ? "dark" : "light",
    },
  });

  const fetchFiles = async () => {
    setLoading(true);
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const fileUrl = urlParams.get("url");

      if (fileUrl) {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const blob = await response.blob();
        const zip = new JSZip();
        const zipContents = await zip.loadAsync(blob);
        const extractedFiles = await extractFilesFromZip(zipContents);
        setFiles(extractedFiles);
        setSelectedFile(extractedFiles[0]);
      } else {
        window.location.href = "https://onehealthassist.com/";
      }
    } catch (error) {
      console.error("Error loading file:", error);
      alert("Error loading file:", error.response)
      window.location.href = "https://onehealthassist.com/";
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          height: "100vh",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                flex: isMobile ? "0 0 35%" : 1,
                overflowY: "auto",
                maxWidth: isMobile ? "100%" : "320px",
                padding: isMobile ? "10px" : 0,
                order: isMobile ? 2 : 1,
              }}
            >
              <Folders
                setSelectedFile={setSelectedFile}
                data={files}
                selectedFile={selectedFile}
              />
            </Box>
            <Box
              sx={{
                flex: isMobile ? "0 0 65%" : 2,
                overflow: "auto",
                padding: "10px",
                order: isMobile ? 1 : 2,
              }}
            >
              {selectedFile && <DwvComponent file={selectedFile} />}
            </Box>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
}
