// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  height: 100vh;
}

.left-side {
  width: 30%;
  max-width: 320px;
  overflow-y: auto;
  border-right: 1px solid #ddd;
  padding: 10px;
}

.right-side {
  width: 70%;
  padding: 10px;
}

.left-side, .right-side {
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,4BAA4B;EAC5B,aAAa;AACf;;AAEA;EACE,UAAU;EACV,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".App {\n  display: flex;\n  height: 100vh;\n}\n\n.left-side {\n  width: 30%;\n  max-width: 320px;\n  overflow-y: auto;\n  border-right: 1px solid #ddd;\n  padding: 10px;\n}\n\n.right-side {\n  width: 70%;\n  padding: 10px;\n}\n\n.left-side, .right-side {\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
