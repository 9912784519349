import React from "react";
import { Box, Typography } from "@mui/material";
import { FileOpen } from "@mui/icons-material";

const Folders = ({ data, setSelectedFile, selectedFile }) => {
  const handleSelect = (file) => {
    if (setSelectedFile) {
      setSelectedFile(file);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      {data.map((file, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            padding: 1,
            cursor: "pointer",
            borderRadius: 1,
            backgroundColor: file.id === selectedFile?.id ? "action.selected" : "transparent",
            "&:hover": {
              backgroundColor: file.id === selectedFile?.id ? "action.selected" : "action.hover",
            },
          }}
          onClick={() => handleSelect(file)}
        >
          <FileOpen color="action" />
          <Typography variant="body1" sx={{ fontWeight: file.id === selectedFile?.id ? 'bold' : 'normal' }}>
            {file.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Folders;
