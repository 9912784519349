import MenuIcon from "@mui/icons-material/Menu";
import ContrastIcon from "@mui/icons-material/Contrast";
import SearchIcon from "@mui/icons-material/Search";
import StraightenIcon from "@mui/icons-material/Straighten";

export const isDicomFile = async (blob) => {
  const arrayBuffer = await blob.slice(0, 132).arrayBuffer();
  const bytes = new Uint8Array(arrayBuffer);
  return (
    bytes[128] === 0x44 &&
    bytes[129] === 0x49 &&
    bytes[130] === 0x43 &&
    bytes[131] === 0x4d
  );
};

export const extractFilesFromZip = async (zip) => {
  const validExtensions = ["dcm", "dicom", "DCM", ""];

  // Helper function to check if a file has a valid extension
  const hasValidExtension = (filename) => {
    const ext = filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
    return validExtensions.includes(`${ext.toLowerCase()}`);
  };

  // Extract and filter files with valid extensions
  const filteredFiles = await Promise.all(
    Object.keys(zip.files).map(async (relativePath) => {
      const zipEntry = zip.files[relativePath];
      if (!zipEntry.dir && hasValidExtension(relativePath)) {
        const fileBlob = await zipEntry.async("blob");
        // Extract only the file name from the relative path
        const fileName = relativePath.split("/").pop();
        return {
          id: null, // Placeholder for ID, to be assigned later
          name: fileName,
          blob: fileBlob,
        };
      }
      return null;
    })
  );

  // Remove null entries (non-matching files)
  const filesWithId = filteredFiles
    .filter((file) => file !== null)
    .map((file, index) => ({
      ...file,
      id: index + 1, // Assign IDs starting from 1
    }));

  return filesWithId;
};

/**
 * Converts an array of Blob objects to a FileList object.
 * @param {Blob[]} blobs - Array of Blob objects to convert.
 * @returns {FileList} - The resulting FileList object.
 */
export const blobsToFileList = (blobs) => {
  // Create a new DataTransfer object
  const dataTransfer = new DataTransfer();

  // Add each blob as a File to the DataTransfer object
  blobs.forEach((blob, index) => {
    // Create a new File object for each Blob
    const file = new File([blob], `file${index}`, { type: blob.type });
    dataTransfer.items.add(file);
  });

  // Return the FileList from the DataTransfer object
  return dataTransfer.files;
};

/**
 * Get the icon of a tool.
 *
 * @param {string} tool The tool name.
 * @returns {Icon} The associated icon.
 */
export const getToolIcon = (tool) => {
  let res;
  if (tool === "Scroll") {
    res = <MenuIcon />;
  } else if (tool === "ZoomAndPan") {
    res = <SearchIcon />;
  } else if (tool === "WindowLevel") {
    res = <ContrastIcon />;
  } else if (tool === "Draw") {
    res = <StraightenIcon />;
  }
  return res;
};
