// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dwv {
  height: 90%; }

.button-row {
  text-align: center;
}

/* Layers */
.layerGroup {
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
  height: 90%;
}
.layer {
  position: absolute;
  pointer-events: none; }
.drawDiv {
  position: absolute; pointer-events: none; }

/* drag&drop */
.dropBox {
  margin: auto;
  text-align: center; vertical-align: middle;
  width: 50%; height: 75%; }
.dropBoxBorder {
  border: 5px dashed #e8eaf6; /*indigo:light*/ }
.dropBoxBorder:hover {
  border: 5px dashed #3f51b5; /*indigo*/  }
#input-file-link {
  text-decoration-line: underline;
}`, "",{"version":3,"sources":["webpack://./src/DwvComponent.css"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAE;;AAEf;EACE,kBAAkB;AACpB;;AAEA,WAAW;AACX;EACE,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,oBAAoB,EAAE;AACxB;EACE,kBAAkB,EAAE,oBAAoB,EAAE;;AAE5C,cAAc;AACd;EACE,YAAY;EACZ,kBAAkB,EAAE,sBAAsB;EAC1C,UAAU,EAAE,WAAW,EAAE;AAC3B;EACE,0BAA0B,EAAE,eAAe,EAAE;AAC/C;EACE,0BAA0B,EAAE,SAAS,GAAG;AAC1C;EACE,+BAA+B;AACjC","sourcesContent":["#dwv {\n  height: 90%; }\n\n.button-row {\n  text-align: center;\n}\n\n/* Layers */\n.layerGroup {\n  position: relative;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n  height: 90%;\n}\n.layer {\n  position: absolute;\n  pointer-events: none; }\n.drawDiv {\n  position: absolute; pointer-events: none; }\n\n/* drag&drop */\n.dropBox {\n  margin: auto;\n  text-align: center; vertical-align: middle;\n  width: 50%; height: 75%; }\n.dropBoxBorder {\n  border: 5px dashed #e8eaf6; /*indigo:light*/ }\n.dropBoxBorder:hover {\n  border: 5px dashed #3f51b5; /*indigo*/  }\n#input-file-link {\n  text-decoration-line: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
