import { Stack, ToggleButton, ToggleButtonGroup} from "@mui/material";
import { RefreshSharp
  } from "@mui/icons-material"
import React from "react";

const Tools = ({
  tools,
  dataLoaded,
  handleToolChange,
  onReset,
  toggleOrientation,
  selectedTool,
  metaData,
  getToolIcon,
  canRunTool
}) => {
  const toolsButtons = Object.keys(tools).map((tool) => {
    return (
      <ToggleButton
        value={tool}
        key={tool}
        title={tool}
        disabled={!dataLoaded || !canRunTool(tool)}
      >
        {getToolIcon(tool)}
      </ToggleButton>
    );
  });

  return (
    <Stack
      direction="row"
      spacing={1}
      padding={1}
      justifyContent="center"
      flexWrap="wrap"
    >
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={selectedTool}
        exclusive
        onChange={handleToolChange}
      >
        {toolsButtons}
      </ToggleButtonGroup>

      <ToggleButton
        size="small"
        value="reset"
        title="Reset"
        disabled={!dataLoaded}
        onChange={onReset}
      >
        <RefreshSharp />
      </ToggleButton>

      <ToggleButton
        size="small"
        value="toggleOrientation"
        title="Toggle Orientation"
        disabled={!dataLoaded}
        onClick={toggleOrientation}
      >
        <CameraswitchIcon />
      </ToggleButton>

      <ToggleButton
        size="small"
        value="tags"
        title="Tags"
        disabled={!dataLoaded}
        onClick={handleTagsDialogOpen}
      >
        <LibraryBooksIcon />
      </ToggleButton>

      <Dialog
        open={state.showDicomTags}
        onClose={handleTagsDialogClose}
        TransitionComponent={TransitionUp}
      >
        <AppBar className={classes.appBar} position="sticky">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleTagsDialogClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              DICOM Tags
            </Typography>
          </Toolbar>
        </AppBar>
        <TagsTable data={metaData} />
      </Dialog>
    </Stack>
  );
};

export default Tools;
